<template>
  <div class="page-title dashboard">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="page-title-content">
            <p>
              Bom trabalho,
              <span>{{user.name}}</span>
            </p>
          </div>
        </div>
        <div class="col-6">
          <ul class="text-right breadcrumbs list-unstyle">
            <li>
              <router-link :to="'settings'"
                >Configurações
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let user = { name: '', email : '', date_expires: '',}
if(localStorage.getItem('user')){
  user = (JSON.parse(localStorage.getItem('user'))).user
}
export default {
  name: "Title",
  props: {
    dark: Boolean,
  },
  data() {
    return {
      user
    }
  }
};
</script>
