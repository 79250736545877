import Vue from "vue";
import VueRouter from "vue-router";
// Link
import Demo from "./views/Demo";
// Landing
import Landing from "./views/Landing"
// auth
import Signin from "./views/auth/Signin";
import Lock from "./views/auth/Lock";
// Dashboard
import Home from "./views/Dashboard/Home"

// Setting
import EditProfile from "./views/Dashboard/Settings/EditProfile"



// VIDA ON
import Clientes from "./views/Dashboard/Clientes"
import CadastrarCliente from "./views/Dashboard/Form/CriarClientes"
import VerCliente from "./views/Dashboard/Form/VerClientesProfile"
import VerClienteTreinos from "./views/Dashboard/Form/VerClientesTreinos"
import VerClienteAlimentacao from "./views/Dashboard/Form/VerClientesAlimentacao"

import Mensagens from "./views/Dashboard/Mensagens"
import CadastrarMensagem from "./views/Dashboard/Form/CriarMensagem"
import EditarMensagem from "./views/Dashboard/Form/EditarMensagem"
import MensagemLogs from "./views/Dashboard/MensagensLogs"

Vue.use(VueRouter);


const routes = [
  // Dashboard
  { path: "/dashboard", name: "Home", component: Home, meta: {requiresAuth: true} },

  // VIDAON
  { path: "/dashboard/clients", name: "Clients", component: Clientes, meta: {requiresAuth: true} },
  { path: "/dashboard/clients/create-client", name: "CadastrarCliente", component: CadastrarCliente, meta: {requiresAuth: true} },
  { path: "/dashboard/clients/view/:id", name: "VerCliente", component: VerCliente, meta: {requiresAuth: true} },
  { path: "/dashboard/clients/view/:id/training", name: "VerClienteTreinos", component: VerClienteTreinos, meta: {requiresAuth: true} },
  { path: "/dashboard/clients/view/:id/food", name: "VerClienteAlimentacao", component: VerClienteAlimentacao, meta: {requiresAuth: true} },

  { path: "/dashboard/messages", name: "Mensagem", component: Mensagens, meta: {requiresAuth: true} },
  { path: "/dashboard/messages/create-message", name: "CadastrarMensagem", component: CadastrarMensagem, meta: {requiresAuth: true} },
  { path: "/dashboard/messages/edit/:id", name: "EditarMensagem", component: EditarMensagem, meta: {requiresAuth: true} },
  { path: "/dashboard/messages/logs", name: "MensagemLogs", component: MensagemLogs, meta: {requiresAuth: true} },

  { path: "/dashboard/settings", name: "EditProfile", component: EditProfile, meta: {requiresAuth: true} },

  // demo
  { path: "/demo", name: "Demo", component: Demo },
  { path: "/", name: "Landing", component: Landing },
  // Auth
  { path: "/signin", name: "Signin", component: Signin },
  { path: "/lock", name: "Lock", component: Lock },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {

  const auth = JSON.parse(localStorage.getItem('user'))
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (auth) {
			next()
		} else {
			next({
				path: '/signin',
			})
		}
	}

	if (to.path === '/signin' && auth) {
		next({
			path: '/dashboard',
		})
	}

	if (to.path === '/signin') {
		next()
	}
})

export default router;
