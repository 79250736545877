/* eslint-disable */
import axios from 'axios'
import authHeader from './auth-header'

// const API_URL = process.env.API_URL ||'http://localhost:3333/api/v1'
const API_URL = 'https://vida-on.herokuapp.com/api/v1'

console.log(authHeader())
class UserService {
	
	get (rota,payload) {
		if(payload){
			return axios.get(`${API_URL}${rota}`, payload, { headers: authHeader() })
		}
		return axios.get(`${API_URL}${rota}`, { headers: authHeader() })

	}
	post (rota,payload) {
		if(payload){
			return axios.post(`${API_URL}${rota}`, payload, { headers: authHeader() })
		}
		return axios.post(`${API_URL}${rota}`, { headers: authHeader() })
	}

	put (rota,payload) {
		if(payload){
			return axios.put(`${API_URL}${rota}`, payload, { headers: authHeader() })
		}
		return axios.put(`${API_URL}${rota}`, { headers: authHeader() })
	}

	delete (rota,payload) {
		if(payload){
			return axios.delete(`${API_URL}${rota}`, payload, { headers: authHeader() })
		}
		return axios.delete(`${API_URL}${rota}`, { headers: authHeader() })
	}
	
}

export default new UserService()
