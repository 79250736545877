import { extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import { email, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Esse campo é necessário.",
});
extend("email", {
  ...email,
  message: "Coloque um e-mail válido.  ",
});

// Custom
extend("passwordFieldName", {
  computesRequired: true,
  validate: (value) => !!value,
  message: (field) => `Por favor, digite a ${field}`,
});

extend("verify_password", {
  validate: (value) => value.length >= 5,
  message: () => `Sua senha deve ter pelo menos 5 caracteres     `,
});

// attach('password', 'required|min:8|verify_password');
// Install messages
localize({
  en,
});
