<template>
  <auth-layout>
    <div class="card-header justify-content-center">
      <h4 class="card-title">Entrar no Vida On</h4>
    </div>
    <div class="card-body">
      <ValidationObserver v-slot="{ passes }" tag="div">
        <form
          @submit.prevent="passes(formSubmit)"
          class="signin_validate"
          action="otp-1"
          novalidate=""
        >
          <ValidationProvider
            rules="required|email"
            v-slot="{ errors }"
            name="method"
            class="form-group"
            tag="div"
          >
            <label>Email</label>
            <input
              type="email"
              class="form-control error"
              placeholder="empresa@vidaon.com.br"
              name="email"
              v-model="email"
              aria-invalid="true"
            />
            <!-- <label class="error" for="email">{{ errors[0] }}</label> -->
            <div class="invalid-feedback d-block">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
          <!-- <ValidationProvider
            rules="passwordFieldName|verify_password"
            v-slot="{ errors }"
            name="password"
            class="form-group"
            tag="div"
          > -->
            <label>Senha</label>
            <input
              type="password"
              class="form-control error"
              placeholder="Password"
              name="password"
              v-model="password"
              aria-invalid="true"
            />
            <div class="invalid-feedback d-block">
              <!-- {{ errors[0] }} -->
            </div>
          <!-- </ValidationProvider> -->
          <div class="d-flex justify-content-between">
            <div class="form-group">
              <router-link
                class="text-primary"
                :to="dark ? 'reset-dark' : 'reset'"
                >Esqueceu a senha?</router-link
              >
            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-primary btn-block">
              Entrar
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </auth-layout>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthLayout from "../../components/Layouts/AuthLayout";
// import store from "@/store/index";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      showError: false,
      showSuccess: false,
      checkbox: "",
      dark: window.location.pathname.includes("dark"),
    };
  },
  methods: {
    formSubmit() {
      const payload = {
        email : this.email,
        password: this.password
      }
      this.$store.dispatch("auth/login", payload).then(async (response) => {
        if(response.token){
          // this.$router.go(0)
          this.sleep(2000)
          this.$router.push(this.$route.query.redirect || '/dashboard')
          this.$toast.success(`Bem vindo! ${response.user.name}, bons treinos.`, );
        }
      }).catch((er) => {
        console.log(er)
        this.$toast.error(`Usuario ou senha está incorreto!`);
      })
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
   }
  },
  mounted() {
    if(this.$router.history.current?.query?.motive == 'timeout'){
      this.$toast.warning(`Você ficou muito tempo sem usar o sistema, entre novamente.`);
    }
  }
}
</script>
