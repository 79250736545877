<template>
  <div class="show">
    <b-tabs content-class="mt-3">
      <div v-for="semana in semanas" :key="semana">
        <b-tab :title="semana" :active="semana == 'Segunda' ? true : false">
          <div class="form-group col-xl-12">
            <label class="me-sm-2">Horário do Treino:</label>
            <input
              style="border-color: #e2815e; width: 150px; display:inline-block"
              maxlength="5"
              type="text"
              v-model="exercicios[semana].time"
              placeholder="10:00"
              class="form-control"
            />
            <button class="btn btn-primary" style="margin-left: 60%" @click="addExercicio(semana)">
              + Exercicio
            </button>
            <hr />
          </div>
          <div
            class="card col-xl-5"
            style="margin-right: 30px; display: inline-block"
            v-for="exercicio in exercicios[semana]"
            :key="exercicio"
          >
            <div class="card-header">
              <label>Exercicio {{ exercicio.id }}</label>
              <button
                class="btn"
                @click="removeExercicio(exercicio.id, semana)"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-xl-12">
                  <div class="media align-items-center mb-3">
                    <div class="media-body">
                      <input
                        type="text"
                        class="form-control"
                        v-model="exercicio.nome"
                        placeholder="Nome do Treino"
                      />
                      <br />
                      <textarea
                        id="story"
                        class="form-control"
                        name="story"
                        rows="5"
                        cols="33"
                        v-model="exercicio.descricao"
                        placeholder="Descrição do Treino"
                      >
                      </textarea>
                      <br />
                      <input
                        type="text"
                        class="form-control"
                        v-model="exercicio.video"
                        placeholder="Link do Video de Demonstração"
                      />
                      <label class="form-group"> o video é opcional! </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </div>
    </b-tabs>
    <hr>
    <button class="btn btn-success" v-show="treinos" @click="updateData()">Atualizar</button>
  </div>
</template>

<script>
export default {
  name: "CadTreinos",
  props: {
    treinos: Object,
    title: Boolean,
    active: Number,
  },
  data() {
    return {
      exercicios: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: [],
        Sabado: [],
        Domingo: [],
      },
      semanas: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sabado",
        "Domingo",
      ],
    };
  },
  mounted() {
    this.checkOnlyView()
    this.$emit("save-training", this.exercicios);
  },
  methods: {
    addExercicio(semana) {
      let indiceInicial = 1;
      let ultimoId = null;
      if (this.exercicios[semana].length !== 0) {
        ultimoId =
          this.exercicios[semana][this.exercicios[semana].length - 1].id + 1;
      }

      const payload = {
        id: ultimoId || indiceInicial,
      };

      this.exercicios[semana].push(payload);
    },
    removeExercicio(id, semana) {
      const novoArray = this.exercicios[semana].filter((el) => el.id !== id);
      this.exercicios[semana] = novoArray;
    },
    checkOnlyView(){
      if(this.treinos){
        this.exercicios.Segunda = JSON.parse(this.treinos.monday)
        this.exercicios['Terça'] = JSON.parse(this.treinos.tuesday)
        this.exercicios.Quarta = JSON.parse(this.treinos.wednesday)
        this.exercicios.Quinta = JSON.parse(this.treinos.thursday)
        this.exercicios.Sexta = JSON.parse(this.treinos.friday)
        this.exercicios.Sabado = JSON.parse(this.treinos.saturday)
        this.exercicios.Domingo = JSON.parse(this.treinos.sunday)
      }
    },
    updateData(){
      if(!this.treinos) return
      this.exercicios.Segunda = this.exercicios.Segunda.filter(x=> x.nome)
      this.exercicios['Terça'] = this.exercicios['Terça'].filter(x=> x.nome)
      this.exercicios.Quarta = this.exercicios.Quarta.filter(x=> x.nome)
      this.exercicios.Quinta = this.exercicios.Quinta.filter(x=> x.nome)
      this.exercicios.Sexta = this.exercicios.Sexta.filter(x=> x.nome)
      this.exercicios.Sabado = this.exercicios.Sabado.filter(x=> x.nome)
      this.exercicios.Domingo = this.exercicios.Domingo.filter(x=> x.nome)

      this.$emit("edit-training", this.exercicios);
    }
  },
};
</script>
