<template>
  <div>
  <loading :active="loadingIsCompleted"></loading>
  <setting-layout :active="1">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Eu,</h4>
        </div>
        <div class="card-body">
          <form action="" @submit.prevent="">
            <div class="form-row">
              <div class="form-group col-xl-12">
                <div class="media align-items-center mb-3">
                  <img
                    class="me-3 rounded-circle me-0 me-sm-3"
                    :src="userData.profile"
                    width="55"
                    height="55"
                    alt=""
                  />
                  <div class="media-body">
                    <h4 class="mb-0">{{userData.name}}</h4>
                    <p class="mb-0">Personal Trainer</p>
                  </div>
                </div>
                <div
                  class="file-upload-wrapper"
                  v-bind:data-text="!filevalue ? 'Mudar Foto' : filevalue"
                >
                  <input
                    name="fileValue"
                    type="file"
                    class="file-upload-field"
                    @change="fileChange"
                  />
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-success">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Alterar Senha</h4>
        </div>
        <div class="card-body">
          <form action="" @submit.prevent="">
            <div class="form-row">
              <div class="form-group col-xl-12">
                <label class="me-sm-2">Nova Senha</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="**********"
                  v-model="newPassword"
                />
              </div>
              <div class="col-12">
                <button class="btn btn-success" @click="changePassword()">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Informações Pessoais</h4>
        </div>
        <div class="card-body">
              <div class="form-row">
                <div class="form-group col-xl-6">
                    <label class="me-sm-2">Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="userData.name"
                    />
                </div>
                <div class="form-group col-xl-6">
                    <label class="me-sm-2">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      readonly
                      v-model="userData.email"
                    />
                </div>
                <div class="form-group col-xl-6 editPage-piker">
                    <label class="me-sm-2 d-block">Vencimento</label>
                    <datepicker
                      v-model="userData.date_expire"
                      disabled
                      :format="customFormatter"
                    ></datepicker>
                </div>
                <div class="form-group col-xl-6">
                    <label class="me-sm-2">Documento</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      v-model="userData.document"
                    />
                </div>
                <div class="form-group col-xl-6">
                    <label class="me-sm-2">Limite de Clientes</label>
                    <input
                      type="number"
                      class="form-control"
                      readonly
                      v-model="userData.limit"
                    />
                </div>
              </div>
        </div>
      </div>
    </div>
  </setting-layout>
  </div>
</template>

<script>
import SettingLayout from "../../../components/Layouts/SettingLayout.vue";
import Loading from "../../../components/Layouts/Loading.vue";

import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import moment from "moment";
import api from '../../../services/funcoes-service'
let user = JSON.parse(localStorage.getItem('user'))
user = user?.user
export default {
  components: {
    SettingLayout,
    Loading,
    Datepicker,
  },
  data() {
    return {
      userData : null,
      newPassword: '',
      loadingIsCompleted: true
    };
  },
  methods: {
    fileChange(e) {
      this.filevalue = e.target.value;
    },
    formSubmit() {
      console.log("Submitting to server!");
    },
    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    async changePassword(){
      try {
        const change = await api.put(`/user/${user.id}`, {password: this.newPassword})
        if(change.data.error == 0){
          this.$toast.success('Informações alteradas com sucesso!')
          this.userData = change.data.usuario
        }
      } catch (error) {
        this.$toast.error('Problema ao salvar alterações!')
      }
    }
  },
  async mounted() {
    try {
      const me = await api.get('/user/me')
      if(me.data.error == 0){
        this.userData = me.data.user
        this.loadingIsCompleted = false
      }
    } catch (error) {
      this.$toast.error('Problema ao consultar seus dados!')
    }
  }
};
</script>
