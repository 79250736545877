<template>
  <div id="toast-container" class="toast-top-right demo_rtl_class">
    <div
      class="toast toast-success"
      aria-live="polite"
      :style="{ opacity: show }"
    >
      <donut />
      <div class="toast-progress" v-bind:style="{ width: time + '%' }"></div>
      <div class="circle_progress" role="button"></div>
      <span class="progress_count" role="button">80<i>%</i></span>
      <i class="la la-close" role="button" @click="show = 0"></i>
      <a href="#" role="button">RENOVAR</a>
      <div class="toast-title">RENOVE SUA LICENÇA DE USO!</div>
      <div class="toast-message">
        Sua data de vencimento está perto, renove o quanto antes.
      </div>
    </div>
  </div>
</template>

<script>
import Donut from "../Tradio/Charts/Donut";
export default {
  name: "Toster",
  components: {
    Donut,
  },
  data() {
    return {
      time: 100,
      show: localStorage.getItem("show_toster_tradio") ? localStorage.getItem("show_toster_tradio") : localStorage.setItem("show_toster_tradio", 1)
    };
  },
  mounted() {
    const chart = document.querySelector("#doughnut-chart");
    chart.style.height = "90px";
    chart.style.width = "90px";
    chart.style.marginLeft = "-4px";
    
    const dataHOJE = new Date()
    const dateStart = new Date(dataHOJE.setDate(dataHOJE.getDate() + 10))
    const user = JSON.parse(localStorage.getItem("user"))
    let dateExpire = new Date(user.user.date_expire)

    if(dateStart >= dateExpire && dateExpire > new Date()){
      localStorage.setItem("show_toster_tradio", 1);
    }else if(dateExpire < new Date()){
      localStorage.setItem("show_toster_expired", 1);
    }

    setInterval(() => {
      this.time > 0 && this.time--;
      if (this.time === 0) {
        this.show = 0;
        localStorage.setItem("show_toster_tradio", 0);
      }
    }, 60);
  },
};
</script>
