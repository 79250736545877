<template>
  <div>
    <loading :active="loadingIsCompleted"> </loading>
    <main-layout :active="2">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Dicas Importantes</h4>
          </div>
          <div class="card-body">
            <div class="important-info">
              <ul>
                <li>
                  <i class="mdi mdi-checkbox-blank-circle"></i>
                  Essas mensagens são padrões, caso você desabilite o envio, não
                  enviará para nenhum de seus clientes.
                </li>
                <li>
                  <i class="mdi mdi-checkbox-blank-circle"></i>
                  DICA: Essas mensagens pode ser lembretes para seus clientes
                  não esquecerem de seus compromissos.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Mensagens Programadas</h4>
            <router-link
              to="/dashboard/messages/create-message"
              class="btn btn-primary"
              >Criar Nova Mensagem</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Mensagem</th>
                    <th>Dias da Semana</th>
                    <th>Horário</th>
                    <th>Enviar</th>
                    <th>Quem vai Receber</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <div v-if="messageData.length <= 0">
                    <h4>SEM REGISTROS</h4>
                  </div>
                  <tr v-for="message in messageData" :key="message">
                    <td>{{ message.message }}</td>
                    <td>
                      <span v-for="(index,value) in JSON.parse(message.day_week)" :key="value">
                      <b-badge v-if="index == true" style="background-color: #756762; margin-left: 2px">{{value.toUpperCase()}}</b-badge>
                      </span>
                      </td>
                    <td><b-badge style="background-color: #756762; margin-left: 2px">{{message.hour}}</b-badge></td>
                    <td>
                      <label class="toggle">
                        <input
                          class="toggle-checkbox"
                          type="checkbox"
                          disabled
                          :checked="message.status == 1 ? true : false"
                        />
                        <span class="toggle-switch"></span>
                      </label>
                    </td>
                    <td>
                      <button
                        class="btn btn-primary"
                        @click="setModalData(message.clients)"
                        v-b-modal.modal-1
                      >
                        Clientes
                      </button>
                    </td>
                    <td>
                      <router-link :to="'/dashboard/messages/edit/' + message.id" class="btn" style="margin-right: 3px"><i class="fa fa-eye" aria-hidden="true"></i></router-link> 
                      <router-link :to="'/dashboard/messages/edit/' + message.id" class="btn"><i class="fa fa-pencil" aria-hidden="true"></i></router-link>

                      <span>
                        <button class="btn" @click="deleteMessage(message)">
                          <i class="mdi mdi-delete fs-20"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-modal
                id="modal-1"
                title="Clientes que vão receber essa mensagem"
                class="modal"
                hide-footer
                v-if="modalData"
              >
              <span v-if="modalData.length <= 0"> <h3> TODOS OS CLIENTES! </h3> </span>
                <div
                  v-for="cliente in modalData"
                  :key="cliente"
                >
                  <img
                    class="me-3 rounded-circle me-0 me-sm-3"
                    :src="cliente.profile"
                    width="40"
                    height="40"
                    alt=""
                  />
                    <label class="mb-0" style="margin-left: 3px">{{
                      cliente.name
                    }}</label>
                    <hr>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from "../../components/Layouts/MainLayout.vue";
import api from "../../services/funcoes-service";
import Loading from "../../components/Layouts/Loading.vue";
export default {
  components: { MainLayout, Loading },
  data() {
    return {
      messageData: null,
      paginationData: null,
      loadingIsCompleted: true,
      modalData: null,
    };
  },
  async mounted() {
    this.getMessages();
  },
  methods: {
    async getMessages(page) {
      try {
        if (!page) page = 1;
        if (page == -1) page = 1;
        const me = await api.get(`/messages?page=${page}&limit=10`);
        if (me.data.error == 0) {
          this.messageData = me.data.messages.data;
          this.paginationData = me.data.messages.meta;
          this.loadingIsCompleted = false;
        }
      } catch (error) {
        this.$toast.error("Problema ao consultar seus dados!");
      }
    },
    setModalData(data) {
      this.modalData = JSON.parse(data);
      console.log(this.modalData)
    },
    async deleteMessage(message) {
      console.log(message)
        this.$bvModal.msgBoxConfirm('Você tem certeza que deseja apagar essa mensagem? Não há como recupera-la.', {
          title: 'Por favor, confirme.',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SIM',
          cancelTitle: 'NÃO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async value => {
            if(value == true){
              const deleta = await api.delete('/messages/'+ message.id)
              if(deleta.data.error == 0){
                this.$toast.success(deleta.data.message)
                this.loadingIsCompleted = true;
                this.getMessages()
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
};
</script>


