<template>
  <div>
    <loading :active="loadingIsCompleted"> </loading>
    <dashboard-layout>
      <div class="content-body">
        <div class="verification section-padding mb-80">
          <div class="container h-100">
            <div
              class="row justify-content-center h-100 align-items-center my-5"
            >
              <div class="col-xl-12 col-md-6" id="otop">
                <div class="auth-form card">
                  <div class="card-header">
                    <h4 class="card-title">Cadastrar novo Cliente</h4>
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="" class="identity-upload">
                      <div class="form-row">
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Nome Completo</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.name"
                          />
                        </div>
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Whatsapp</label>
                          <input
                            type="text"
                            placeholder="11943219222 (DDD + NUMERO)"
                            class="form-control"
                            v-model="form.whatsapp"
                          />
                        </div>
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Mensalidade R$</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="form.price"
                          />
                          <label>Valor dos serviços para esse cliente</label>
                        </div>
                        <div class="form-group col-xl-12 editPage-piker">
                          <label class="me-sm-2">Vencimento do Contrato</label>
                          <datepicker
                            v-model="form.date_limit"
                            :format="customFormatter"
                          ></datepicker>
                        </div>

                        <div class="form-group col-xl-12">
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              checked
                              v-model="form.permitirenvios"
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label"
                              >Enviar mensagens programadas no Whatsapp</span
                            >
                          </label>
                        </div>
                        <hr />
                        <div class="form-group col-xl-12">
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="treino"
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Cadastrar Treino</span>
                          </label>
                        </div>
                        <div class="form-control" v-show="treino">
                          <button class="btn" @click="expandir()">
                            <i class="fa fa-search-plus" aria-hidden="true"></i>
                          </button>
                          <CadTreino @save-training="saveTraining" />
                        </div>
                        <div class="form-group col-xl-12">
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="alimentacao"
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label"
                              >Cadastrar Alimentação</span
                            >
                          </label>
                        </div>
                        <div class="form-control" v-show="alimentacao">
                          <button class="btn" @click="expandir()">
                            <i class="fa fa-search-plus" aria-hidden="true"></i>
                          </button>
                          <CadAlimentacao @save-food="saveFood" />
                        </div>
                        <div class="text-center col-12">
                          <router-link
                            to="/dashboard/clients"
                            class="btn btn-primary mx-2"
                            >Voltar</router-link
                          >
                          <button type="submit" class="btn btn-success mx-2" @click="createClient()">
                            Criar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dashboard-layout>
  </div>
</template>

<script>
import DashboardLayout from "../../../components/Layouts/DashboardLayout.vue";
import CadTreino from "../../../components/Form/CadTreino.vue";
import CadAlimentacao from "../../../components/Form/CadAlimentacao.vue";

import api from "../../../services/funcoes-service";
import Loading from "../../../components/Layouts/Loading.vue";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import moment from "moment";

export default {
  components: { DashboardLayout, CadTreino, Loading, Datepicker , CadAlimentacao},
  data() {
    return {
      margin: 5,
      treino: false,
      alimentacao: false,
      loadingIsCompleted: false,
      trainingData: [],
      foodData: [],
      form: {
        name: "",
        whatsapp: "",
        permitirenvios: false,
        price: "",
        date_limit: "",
      },
    };
  },
  methods: {
    expandir() {
      let el = document.getElementById("otop");
      const indexInicia = el.attributes[1].nodeValue.indexOf("col-xl");
      let somaIndices = indexInicia + 7;
      if (indexInicia !== 0) {
        somaIndices = indexInicia + 7;
      }

      console.log(
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1)
      );
      if (
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1) == "5"
      ) {
        el.classList.remove("col-xl-5");
        el.classList.add("col-xl-12");
      } else {
        el.classList.remove("col-xl-12");
        el.classList.add("col-xl-5");
      }
    },

    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    async createClient() {
      try {
        const payload = {
          client: {
            name: this.form.name,
            whatsapp: '55' + this.form.whatsapp,
            allow_message_whatsapp: this.form.permitirenvios,
            price_default: this.form.price,
            date_limit: moment(this.form.date_limit,).format("YYYY-MM-DD")
          },
          training: this.treino ? this.trainingData : null,
          food: this.alimentacao ? this.foodData : null
        };
        const pay = await api.post("/clients", payload);
        if(pay.data.error == 0){
          this.$toast.success("Cliente cadastrado com sucesso!!");
          this.$router.push('/dashboard/clients')
        }
      } catch (error) {
        this.$toast.error("Problema ao criar cliente, verifique os dados!");
      }
    },

    saveTraining (value) {
      this.trainingData = value
    },
    saveFood (value) {
      this.foodData = value
    }
  },
};
</script>
