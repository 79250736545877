<template>
<div>
  <loading :active="loadingIsCompleted"> </loading>
  <cliente-layout :active="3">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Alimentação</h4>
        </div>
        <div class="card-body">
          <CadAlimentacao v-if="foodData" :food="foodData" @edit-food="updateFood" />
        </div>
      </div>
    </div>
  </cliente-layout>
  </div>
</template>

<script>
import ClienteLayout from "../../../components/Layouts/ClienteLayout.vue";
import CadAlimentacao from "../../../components/Form/CadAlimentacao.vue"
import api from "../../../services/funcoes-service";
import Loading from "../../../components/Layouts/Loading.vue";

export default {
  components: {
    ClienteLayout,
    Loading,
    CadAlimentacao
  },
  data() {
    return {
      foodData: null,
      loadingIsCompleted: true,
    };
  },
  created(){
    this.getInfoFood()
  },
  methods: {
    async getInfoFood() {
      try {
        const food = await api.get(`/food/get/client/${this.$router.history.current.params.id}`)
        if(food.data.error == 0){
          this.foodData = food.data.food;
          this.$toast.success(food.data.message);
        }else{
          this.$toast.error(food.data.message);
        }
        this.loadingIsCompleted = false;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    },

    async updateFood(exercicios){
      try {
        const food = await api.put(`/food/client/${this.$router.history.current.params.id}`, {food: exercicios});
        if(food.data.error == 0){
          this.foodData = food.data.food;
          this.$toast.success(food.data.message);
        }else{
          this.$toast.error(food.data.message);
        }
        this.loadingIsCompleted = false;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    }
  },
};
</script>
