<template>
  <div>
    <loading :active="loadingIsCompleted"> </loading>
    <dashboard-layout>
      <div class="content-body">
        <div class="verification section-padding mb-80">
          <div class="container h-100">
            <div
              class="row justify-content-center h-100 align-items-center my-5"
            >
              <div class="col-xl-12 col-md-6" id="otop">
                <div class="auth-form card">
                  <div class="card-header">
                    <h4 class="card-title">Cadastrar nova Mensagem</h4>
                  </div>
                  <div class="card-body">
                      <div class="form-row">
                        <div class="form-group col-xl-12">
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="exclusiva"
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Mensagem Exclusiva</span>
                          </label>
                          <label>
                            Se você marcar essa caixa, você irá escolher os
                            clientes que vão receber essa mensagem, se deixar
                            desmarcado, todos os clientes vão receber
                          </label>
                        </div>
                        <!-- Seleciona os clientes -->
                        <div v-show="exclusiva">
                          <button class="btn btn-primary" @click="expandir()">
                            <i class="fa fa-search-plus" aria-hidden="true"></i>
                          </button>
                          <div class="form-group col-xl-12">
                            <label class="me-sm-2">Nome do Cliente</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="clientNameSearch"
                            />
                            <br />
                            <div
                              id="resultadoDaBusca"
                              v-show="clientsFound.length > 0"
                            >
                              <h4>Resultado da busca:</h4>
                              <br />
                              <div
                                class="media"
                                style="border: 1px solid #f2f3f8"
                                v-for="cliente in clientsFound"
                                :key="cliente.id"
                              >
                                <span>
                                  <img
                                    class="me-3 rounded-circle me-0 me-sm-3"
                                    :src="cliente.profile"
                                    width="40"
                                    height="40"
                                    alt=""
                                  />
                                  <button
                                    class="btn"
                                    @click="addClient(cliente)"
                                  >
                                    <i
                                      class="fa fa-plus"
                                      style="color: #50872c"
                                    ></i>
                                    <label
                                      class="mb-0"
                                      style="margin-left: 3px"
                                      >{{ cliente.name }}</label
                                    >
                                  </button>
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div id="resultadoDaBusca">
                              <h4 style="color: #50872c">Adicionados:</h4>
                              <br />
                              <div
                                class="media"
                                style="border: 1px solid #f2f3f8"
                                v-show="clientsAdded.length > 0"
                                v-for="cliente in clientsAdded"
                                :key="cliente.id"
                              >
                                <span>
                                  <img
                                    class="me-3 rounded-circle me-0 me-sm-3"
                                    :src="cliente.profile"
                                    width="40"
                                    height="40"
                                    alt=""
                                  />
                                  <button
                                    class="btn"
                                    @click="removeClient(cliente)"
                                  >
                                    <i
                                      class="fa fa-minus"
                                      style="color: #aa0b0b"
                                    ></i>
                                    <label
                                      class="mb-0"
                                      style="margin-left: 3px"
                                      >{{ cliente.name }}</label
                                    >
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Seleciona os clientes -->
                        <hr v-show="exclusiva" />
                        <hr />
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Horario de Envio</label>
                          <input
                            class="form-control"
                            v-model="hora"
                            placeholder="10:00"
                            type="text"
                          />
                        </div>
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Mensagem</label>
                          <textarea
                            id="story"
                            class="form-control"
                            name="story"
                            rows="5"
                            v-model="mensagem"
                            cols="33"
                            placeholder="Bom dia, não esqueça de comer {xxx} no seu café da manhã"
                          >
                          </textarea>
                          <label>Atalhos para mensagem:</label>
                          <h4>
                            <button @click="addToMessage('{alimentacao}')">
                              <b-badge style="background-color: #9615c1"
                                >{alimentacao}
                              </b-badge>
                            </button>
                            -
                            <button @click="addToMessage('{treino}')">
                              <b-badge style="background-color: #2d225e">
                                {treino}</b-badge
                              >
                            </button>
                          </h4>
                        </div>
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Dias da Semana</label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.segunda"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Segunda-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.terca"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Terça-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.quarta"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Quarta-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.quinta"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Quinta-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.sexta"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Sexta-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.sabado"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Sabado-Feira</span>
                          </label>
                          <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              v-model="semana.domingo"
                              checked
                            />
                            <span class="toggle-switch"></span>
                            <span class="toggle-label">Domingo-Feira</span>
                          </label>
                        </div>
                        <div class="text-center col-12">
                          <router-link
                            to="/dashboard/messages"
                            class="btn btn-primary mx-2"
                            >Voltar</router-link
                          >
                          <button type="submit" @click="formSubmit" class="btn btn-success mx-2">
                            Criar
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dashboard-layout>
  </div>
</template>

<script>
import DashboardLayout from "../../../components/Layouts/DashboardLayout.vue";
import api from "../../../services/funcoes-service";
import Loading from "../../../components/Layouts/Loading.vue";
export default {
  components: { DashboardLayout, Loading },
  data() {
    return {
      margin: 5,
      exclusiva: false,
      mensagem: "",
      clientNameSearch: "",
      hora: "",
      clientsFound: [],
      clientsAdded: [],
      loadingIsCompleted: true,
      semana: {
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: true,
        domingo: true,
      },
    };
  },
  mounted() {
    this.loadingIsCompleted = false;
  },
  watch: {
    async clientNameSearch(cod){
      try {
        const clients = await api.get(
          "/clients/view/name/" + cod
      );
        this.clientsFound = clients.data.client;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    }
  },
  methods: {
    expandir() {
      let el = document.getElementById("otop");
      const indexInicia = el.attributes[1].nodeValue.indexOf("col-xl");
      let somaIndices = indexInicia + 7;
      if (indexInicia !== 0) {
        somaIndices = indexInicia + 7;
      }

      console.log(
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1)
      );
      if (
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1) == "5"
      ) {
        el.classList.remove("col-xl-5");
        el.classList.add("col-xl-12");
      } else {
        el.classList.remove("col-xl-12");
        el.classList.add("col-xl-5");
      }
    },
    addToMessage(addtexto) {
      this.mensagem = this.mensagem + addtexto;
    },

    addClient(cliente) {
      if (this.clientsAdded.find((x) => x.id == cliente.id)) return;

      this.clientsFound = this.clientsFound.filter((x) => x !== cliente);
      this.clientsAdded.push(cliente);
    },

    removeClient(cliente) {
      this.clientsAdded = this.clientsAdded.filter((x) => x !== cliente);
    },

    async formSubmit() {
      try {
        const payload = {
          day_week: this.semana,
          message: this.mensagem,
          clients: this.exclusiva ? this.clientsAdded : [],
          hour: this.hora,
        };

        const create = await api.post("/messages", payload);
        if (create.data.error == 0) {
          this.$toast.success(create.data.message);
        } else {
          this.$toast.error(create.data.message);
        }
        console.log(payload);
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    },
  },
};
</script>
