<template>
  <div>
    <loading :active="loadingIsCompleted"> </loading>
    <main-layout :active="4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Logs de Envios</h4>
            <div>
              <ul class="pagination">
                <li class="page-item">
                  <button class="page-link" @click="getMessages(paginationData.current_page - 1)" aria-label="Anterior">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Anterior</span>
                  </button>
                </li>
                <li class="page-item"><button class="page-link" @click="getMessages(1)">1</button></li>
                <li class="page-item"><button class="page-link" @click="getMessages(2)">2</button></li>
                <li class="page-item"><button class="page-link" @click="getMessages(3)">3</button></li>
                <li class="page-item"><button class="page-link" @click="getMessages(4)">4</button></li>
                <li class="page-item"><button class="page-link" @click="getMessages(5)">5</button></li>
                <li class="page-item">
                  <button class="page-link" @click="getMessages(paginationData.current_page + 1)" aria-label="Próximo">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Próximo</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Mensagem</th>
                    <th>Horário Enviado</th>
                    <!-- <th>Outro/Detalhe</th> -->
                  </tr>
                </thead>
                <tbody>
                  <div v-if="messageLogsData.length <= 0">
                    <h4> SEM REGISTROS </h4>
                    </div> 
                  <tr v-for="item in messageLogsData" :key="item.id">
                    <td><a href="/dashboard/clients/view/1">{{ item.client.name }}</a></td>
                    <td>{{ item.message }}</td>
                    <td>{{ item.created_at }}</td>
                    <!-- <td>{{ item.other }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from "../../components/Layouts/MainLayout.vue";
import api from "../../services/funcoes-service";
// const user = JSON.parse(localStorage.getItem('user')).user
import Loading from "../../components/Layouts/Loading.vue";

export default {
  components: { MainLayout, Loading },
  data() {
    return {
      messageLogsData: null,
      paginationData: null,
      loadingIsCompleted: true,
    };
  },
  async mounted() {
    this.getMessages()
  },
  methods: {
    async getMessages(page){
      try {
        if(!page) page = 1
        if(page == -1) page = 1
      const me = await api.get(`/messages/logs?page=${page}&limit=10`);
      if (me.data.error == 0) {
        this.messageLogsData = me.data.logs.data;
        this.paginationData = me.data.logs.meta;
        this.loadingIsCompleted = false;
      }
    } catch (error) {
      this.$toast.error("Problema ao consultar seus dados!");
    }
    }
  }
};
</script>
