import { BootstrapVue, BVToastPlugin } from "bootstrap-vue";

// import "bootstrap-vue/dist/bootstrap-vue.css";
// import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import LoadScript from "vue-plugin-load-script";
import store from './store'
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "vue-toastification/dist/index.css";
import App from "./App.vue";
import "./assets/css/custome.css";
import "./assets/css/popup.css";
import "./assets/css/style.css";
import "./assets/css/dark.css";
import router from "./router";
import "./util/errrorMessage";
import AuthService  from './services/auth-service'
import Toast from "vue-toastification";

Vue.use(LoadScript);
Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;
Vue.use(BVToastPlugin)
const options = {
  timeout: 4000
};
Vue.use(Toast, options);
Vue.use(BootstrapVue);
Vue.use(PerfectScrollbar);

async function validLogin(){
  const logged = await AuthService.valid();
  if(logged == false){
    localStorage.removeItem('user')
    if(router.history.current.query.motive){
      router.push('/signin?motive=timeout')
    }else{
      router.push('/signin')
    }
  }
}

validLogin()
// Vue.use(VeeValidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
