<template>
<div>
  <loading :active="loadingIsCompleted"> </loading>
  <dashboard-layout :active="1" :container="true">
    <toster />
    <div class="row">
      <div class="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Relatório da Conta</h4>
            <span>Atualizado à <span>10</span> minutos atrás</span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Total de Clientes</p>
                <h6>{{dataDashboard.totalClients}} <strong class="text-success">R$ {{dataDashboard.totalMoney}}</strong></h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Treinos Hoje</p>
                <h6>{{dataDashboard.totalTrainingsToday}}</h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Perfil</p>
                <h6>{{dataDashboard.profile.role}}</h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">BOT</p>
                <h6><strong class="text-danger">Não Conectado!</strong></h6>
                <!-- <h6><strong class="text-success">Conectado!</strong></h6> -->
              </div>
               <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Data de Vencimento</p>
                <h6>{{formatDate(dataDashboard.profile.dateExpire)}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Relatório do Site</h4>
            <span>Atualizado à <span>10</span> minutos atrás</span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Visualizações</p>
                <h6>0</h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Entrou em Contato</p>
                <h6>0</h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Tempo médio</p>
                <h6>0 minutos</h6>
              </div>
              <div class="col-xl col-lg col-md col-sm-auto col-6">
                <p class="mb-0">Online no Momento</p>
                <h6>0</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-xxl-6">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Treinos Hoje ({{new Date().getDate()}}/{{new Date().getMonth() + 1}})</h4>
                <abbr title="Caso você não marque a presença, será contabilizado falta!"><i class="fa fa-info-circle"></i></abbr>
              </div>
              <perfect-scrollbar class="card-body trade-history">
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Whatsapp</th>
                        <th scope="col">Treino</th>
                        <th scope="col">Compareceu</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="workout in dataDashboard.trainingsToday" :key="workout.id">
                        <td><strong>{{workout.client_name}}</strong></td>
                        <td><strong><a target="_blank" :href="'https://wa.me/' + workout.client_whatsapp">{{workout.client_whatsapp}}</a></strong></td>
                        <td><button @click="setDataModalTemp(JSON.parse(workout.exercises))" v-b-modal.modal-1 class="btn"><i class="fa fa-search-plus"></i></button></td>
                        <td>
                           <label class="toggle">
                            <input
                              class="toggle-checkbox"
                              type="checkbox"
                              :checked="workout.presenceToday"
                              @click="markPresenceOrNot(workout)"
                            />
                            <span class="toggle-switch"></span>
                          </label>

                          <label style="margin-right: 5px" v-show="workout.presenceToday == true"><i class="fa fa-check"></i> Sim</label>
                          <label style="margin-right: 5px" v-show="workout.presenceToday == false"><i class="fa fa-close"></i> Não</label></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="modal-1" title="Treinos">
        <div
            class="card col-xl-5"
            style="margin-right: 10px; display: inline-block; background-color: #ededed;"
            v-for="exercicio in modalDataExercises"
            :key="exercicio"
          >
            <div class="card-header" style="background-color: #ededed">
              <label>Exercicio {{ exercicio.id }}</label>
            </div>
            <div class="card-body" >
              <div class="form-row">
                <div class="form-group col-xl-12">
                  <div class="media align-items-center mb-3">
                    <div class="media-body">
                      <p>{{exercicio.nome}}</p>
                      <p>{{exercicio.descricao}}</p>
                      <button class="btn btn-dark" :disabled="!exercicio.video"><a target="_blank" :href="exercicio.video">Ver Video </a></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </b-modal>

      <div class="col-xl-6 col-lg-6 col-md-12 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">BOT {{dataDashboard.profile.name}}</h4>
          </div>
          <perfect-scrollbar class="card-body trade-history">
            <div class="total-balance">
              <h3 v-if="message.color == 'red'" class="text">{{message.msg}}</h3>
              <h3 v-if="message.color == 'green'" class="text-success">{{message.msg}}</h3>
              <h3 v-if="message.color == 'orange'" class="text-primary">{{message.msg}}</h3>


              <h6>Status do BOT</h6>
              <br>
              <button v-if="botConnected == true" type="submit" @click="desconectar" name="submit" class="btn btn-danger">
                  Desligar BOT
                </button>
            </div>
            <div class="center">
              <hr>
              <div v-if="botConnected == false" class="btn-group btn-block mt-3" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                <div v-show="ligarBot == false">
                  <h6> Abra seu whatsapp e scaneie esse QRCODE </h6>
                  <br />
                  <img width="280px" :src="this.qrcode" />
                  <br />
                  <hr>
                  <b-progress :value="contador" max="100" show-value class="mb-3"></b-progress>
                </div>
                <div v-show="ligarBot == true">
                  <h6> Para ligar seu BOT clique no botão </h6>
                  <button @click="startBot" type="submit" name="submit" class="btn btn-success">
                    Ligar BOT
                  </button>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
      <!-- <div class="col-xl-12 col-lg-12 col-xxl-12">
        <div class="card">
          <b-tabs
            class="tab-content tab-content-default"
            nav-wrapper-class="card-header"
            pills
          >
            <b-tab title="Open Position">
              <perfect-scrollbar class="card-body">
                <div class="table-responsive home-table">
                  <table class="table table-striped" id="tbUser">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Size</th>
                        <th scope="col">Entry Value</th>
                        <th scope="col">Entry Price</th>
                        <th scope="col">Liq. Price</th>
                        <th scope="col">Margin</th>
                        <th scope="col">Eff. Lvg.</th>
                        <th scope="col">Unrealized P&L (ROE %)</th>
                        <th scope="col">Realized P&L</th>
                        <th scope="col">Close</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5698.25</td>
                        <td>0.3658745698 BTC</td>
                        <td>0.32658745566</td>
                        <td>0.95874585254 BTC</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td>0.24566</td>
                        <td><i class="la la-close btnDelete"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
            </b-tab>
            <b-tab title="Active Orders">
              <perfect-scrollbar class="card-body">
                <div class="table-responsive home-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Type</th>
                        <th scope="col">Size</th>
                        <th scope="col">Filled</th>
                        <th scope="col">Remaining</th>
                        <th scope="col">Limit Price</th>
                        <th scope="col">Fill Price</th>
                        <th scope="col">Rem. Value</th>
                        <th scope="col">Stop Price</th>
                        <th scope="col">Status</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
            </b-tab>
            <b-tab title="Fills">
              <perfect-scrollbar class="card-body">
                <div class="table-responsive home-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Size</th>
                        <th scope="col">Entry Price</th>
                        <th scope="col">Entry Value</th>
                        <th scope="col">Time</th>
                        <th scope="col">OrderID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>5623.58</td>
                        <td>5623.58</td>
                        <td>0.24566 BTC</td>
                        <td>07:25:35</td>
                        <td>#2658455</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
            </b-tab>
            <b-tab title="Liguidition">
              <perfect-scrollbar class="card-body">
                <div class="table-responsive home-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Size</th>
                        <th scope="col">Value</th>
                        <th scope="col">Entry Price</th>
                        <th scope="col">Mark Price</th>
                        <th scope="col">Margim</th>
                        <th scope="col">Leverage</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                      <tr>
                        <th scope="row"><i class="cc BTC"></i></th>
                        <td>BTC - USD</td>
                        <td>5623.58</td>
                        <td>-</td>
                        <td>0.95874</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566 BTC</td>
                        <td>0.24566</td>
                        <td>0.02543</td>
                        <td>Complete</td>
                        <td>14:41:36</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </div>
      </div> -->
    </div>
  </dashboard-layout>
</div>
</template>

<script>
import DashboardLayout from "../../components/Layouts/DashboardLayout.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Toster from "../../components/Tradio/Toster.vue";
import api from "../../services/funcoes-service";
import Loading from "../../components/Layouts/Loading.vue";
import moment from 'moment'
export default {
  components: {
    DashboardLayout,
    PerfectScrollbar,
    Toster,
    Loading
  },
  data() {
    return {
      sliderValue: 0,
      dataDashboard: { profile : {}},
      loadingIsCompleted: true,
      modalDataExercises: [],
      ligarBot: true,
      botConnected: false,
      qrcode: '',
      message: {
        msg: 'DESCONECTADO!',
        color: 'red'
      },
      contador: 0,
    };
  },
  mounted() {
    this.getDataDashboard();
  },
  methods: {
    onChange(e) {
      // console.log(e.target.value);
      this.sliderValue = e.target.value;
    },

    async getDataDashboard(ondeVeio = 'index') {
      try {
        const datas = await api.get(`/dashboard`);
        console.log(datas);
        if(datas.data.error == 1) return this.$toast.error(datas.data.message);
        this.dataDashboard = datas.data;
        if(ondeVeio == 'index'){
          this.checkBot();
        }

        this.loadingIsCompleted = false
      } catch (error) {
        console.log(error)
        return this.$toast.error('Problema ao carregar dashboard!');
      }
    },

    setDataModalTemp(exercises){
      this.modalDataExercises = exercises;
    },

    async markPresenceOrNot(client){
      try {
        let presence = client.presenceToday == false ? true : false
        const update = await api.post('/dashboard/training/history', {presence: presence, client_id: client.client_id})
        if(update.data.error == 1) return this.$toast.error(update.data.message);

        this.$toast.success(update.data.message);
        this.getDataDashboard('presence');
      } catch (error) {
        this.$toast.error("Problema ao alterar presença!")
      }
    },

    async startBot() {
      try {
        const datas = await api.post(`/bot/create/session/a${this.dataDashboard.profile.id}b${this.dataDashboard.profile.name.replace(' ', '-')}c`, {});
        if(datas.data.data.error == 1) return this.$toast.error(datas.data.data.message);
        return this.geraQrCode()

      } catch (error) {
        console.log(error)
        return this.$toast.error('Problema ao carregar dashboard!');
      }
    },

    async geraQrCode() {
      try{
        const datas = await api.post(`/bot/a${this.dataDashboard.profile.id}b${this.dataDashboard.profile.name.replace(' ', '-')}c/start`, {})
        if(datas.data.error == 1) return this.$toast.error(datas.data.message)
        if(datas.data.data.status == 'CONNECTED') {
          this.message.msg = 'CONECTADO!'
          this.message.color = 'green'

          return this.$toast.success('Seu bot já está conectado!')
        }
        this.message.msg = 'INICIANDO...'
        this.$toast.success('Aguarde... Iniciando whatsapp!')
        return this.checkQrCode()
      }catch(error){
        console.log(error)
      }
    },

    async checkQrCode() {
      try {
        const datas = await api.get(`/bot/a${this.dataDashboard.profile.id}b${this.dataDashboard.profile.name.replace(' ', '-')}c/check-qrcode`)
        if(datas.data.error == 1) return this.$toast.error(datas.data.message)

        if(datas.data.data.status == 'CONNECTED'){
          this.botConnected = true
          this.message.msg = 'CONECTADO!'
          this.message.color = 'green'
          return this.$toast.success('Seu bot está conectado!')
        }
        this.ligarBot = false;
        if(datas.data.data.status == 'INITIALIZING' || datas.data.data.status == 'QRCODE'){
        if (datas.data.data.status == 'INITIALIZING') this.message.msg = 'GERANDO QR CODE...'
        if (datas.data.data.status == 'QRCODE'){
          this.message.msg = 'AGUARDANDO SCANEAR...'
          this.message.color = 'orange'
        }

        this.qrcode = datas.data.data.qrcode
        await this.sleep(1000)
        if(this.contador === 100) this.contador = 0
        this.contador = this.contador + 10
        return this.checkQrCode()
        }

      } catch (error) {
        console.log(error)
      }
    },

    async checkBot(){
      const datas = await api.get(`/bot/a${this.dataDashboard.profile.id}b${this.dataDashboard.profile.name.replace(' ', '-')}c/check-session`)
      console.log(datas)
      if(datas.data.error == 1) return this.$toast.error(datas.data.message)

      if(datas.data.data.status == true){
        this.botConnected = true
        this.message.msg = 'CONECTADO!'
        this.message.color = 'green'
        return this.$toast.success('Seu bot está conectado!')
      }
        return this.$toast.danger('Seu bot não está conectado!')
    },

    async desconectar(){
      try {
        const datas = await api.post(`/bot/a${this.dataDashboard.profile.id}b${this.dataDashboard.profile.name.replace(' ', '-')}c/close-session`, {})
        if(datas.data.error == 1) return this.$toast.error(datas.data.message)
        this.botConnected = false
        this.message.msg = 'DESCONECTADO!'
        this.message.color = 'red'
        return this.$toast.success('Seu bot foi desconectado!')
      } catch (error) {
        console.log(error)
      }
    },

    formatDate(date){
      const newdate = `${moment(date)
      .utc()
      .format("DD/MM/YYYY")}`;
      this.date_end = newdate;
      return newdate;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
   }
  },
};
</script>
