<template>
  <dashboard-layout>
    <div class="content-body">
      <div class="verification section-padding mb-80">
        <div class="container h-100">
          <div class="row justify-content-center h-100 align-items-center my-5">
            <div class="col-xl-5 col-md-6" id="otop">
              <div class="auth-form card">
                <div class="card-header">
                  <h4 class="card-title">Cadastrar nova Mensagem</h4>
                </div>
                <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-xl-12">
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="exclusiva"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Mensagem Exclusiva</span>
                        </label>
                        <label>
                          Se você marcar essa caixa, você irá escolher os
                          clientes que vão receber essa mensagem, se deixar
                          desmarcado, todos os clientes vão receber
                        </label>
                      </div>
                      <!-- Seleciona os clientes -->
                      <div v-show="exclusiva">
                        <button class="btn btn-primary" @click="expandir()">
                          <i class="fa fa-search-plus" aria-hidden="true"></i>
                        </button>
                        <div class="form-group col-xl-12">
                          <label class="me-sm-2">Nome do Cliente</label>
                          <input type="text" class="form-control" v-model="clientNameSearch" />
                          <button class="btn" @click="searchClient">
                              Buscar
                            </button>
                          <br />
                          <div
                              id="resultadoDaBusca"
                              v-show="clientsFound.length > 0"
                            >
                              <h4>Resultado da busca:</h4>
                              <br />
                              <div
                                class="media"
                                style="border: 1px solid #f2f3f8"
                                v-for="cliente in clientsFound"
                                :key="cliente.id"
                              >
                                <span>
                                  <img
                                    class="me-3 rounded-circle me-0 me-sm-3"
                                    :src="cliente.profile"
                                    width="40"
                                    height="40"
                                    alt=""
                                  />
                                  <button
                                    class="btn"
                                    @click="addClient(cliente)"
                                  >
                                    <i
                                      class="fa fa-plus"
                                      style="color: #50872c"
                                    ></i>
                                    <label
                                      class="mb-0"
                                      style="margin-left: 3px"
                                      >{{ cliente.name }}</label
                                    >
                                  </button>
                                </span>
                              </div>
                            </div>

                          <hr />

                          <div id="resultadoDaBusca">
                              <h4 style="color: #50872c">Adicionados:</h4>
                              <br />
                              <div
                                class="media"
                                style="border: 1px solid #f2f3f8"
                                v-show="messageData.clients.length > 0"
                                v-for="cliente in messageData.clients"
                                :key="cliente.id"
                              >
                                <span>
                                  <img
                                    class="me-3 rounded-circle me-0 me-sm-3"
                                    :src="cliente.profile"
                                    width="40"
                                    height="40"
                                    alt=""
                                  />
                                  <button
                                    class="btn"
                                    @click="removeClient(cliente)"
                                  >
                                    <i
                                      class="fa fa-minus"
                                      style="color: #aa0b0b"
                                    ></i>
                                    <label
                                      class="mb-0"
                                      style="margin-left: 3px"
                                      >{{ cliente.name }}</label
                                    >
                                  </button>
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                      <!-- Seleciona os clientes -->
                      <hr v-show="exclusiva" />
                      <hr />
                      <div class="form-group col-xl-12">
                        <label class="me-sm-2">Mensagem</label>
                        <textarea
                          id="story"
                          class="form-control"
                          name="story"
                          rows="5"
                          v-model="messageData.message"
                          cols="33"
                          placeholder="Bom dia, não esqueça de comer {xxx} no seu café da manhã"
                        >
                        </textarea>
                        <label>Atalhos para mensagem:</label>
                        <h4>
                          <button @click="addToMessage('{alimentacao}')">
                            <b-badge style="background-color: #9615c1"
                              >{alimentacao}
                            </b-badge>
                          </button>
                          -
                          <button @click="addToMessage('{treino}')">
                            <b-badge style="background-color: #2d225e">
                              {treino}</b-badge
                            >
                          </button>
                        </h4>
                      </div>
                      <div class="form-group col-xl-12">
                        
                        <label class="me-sm-2">Dias da Semana</label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.segunda"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Segunda-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.terca"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Terça-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.quarta"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Quarta-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.quinta"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Quinta-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.sexta"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Sexta-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.sabado"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Sabado-Feira</span>
                        </label>
                        <label class="toggle">
                          <input
                            class="toggle-checkbox"
                            type="checkbox"
                            v-model="semana.domingo"
                          />
                          <span class="toggle-switch"></span>
                          <span class="toggle-label">Domingo-Feira</span>
                        </label>
                      </div>
                      <div class="text-center col-12">
                        <router-link
                          to="/dashboard/messages"
                          class="btn btn-primary mx-2"
                          >Voltar</router-link
                        >
                        <button @click="updateMessage()" class="btn btn-success mx-2">
                          Atualizar
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../../components/Layouts/DashboardLayout.vue";
import api from "../../../services/funcoes-service";

export default {
  components: { DashboardLayout },
  data() {
    return {
      margin: 5,
      exclusiva: false,
      mensagem: "",
      messageData: [],
      clientNameSearch: '',
      clientsFound: [],
      semana: {}
    };
  },
  mounted() {
    this.getMessage()
  },
  methods: {
    expandir() {
      let el = document.getElementById("otop");
      const indexInicia = el.attributes[1].nodeValue.indexOf("col-xl");
      let somaIndices = indexInicia + 7;
      if (indexInicia !== 0) {
        somaIndices = indexInicia + 7;
      }

      console.log(
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1)
      );
      if (
        el.attributes[1].nodeValue.slice(somaIndices, somaIndices + 1) == "5"
      ) {
        el.classList.remove("col-xl-5");
        el.classList.add("col-xl-12");
      } else {
        el.classList.remove("col-xl-12");
        el.classList.add("col-xl-5");
      }
    },
    addToMessage(addtexto) {
      this.messageData.message = this.messageData.message + addtexto;
    },

    async searchClient() {
      try {
        const clients = await api.get(
          "/clients/view/name/" + this.clientNameSearch
        );
        this.clientsFound = clients.data.client;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    },

    addClient(cliente) {
      if (this.messageData.clients.find((x) => x.id == cliente.id)) return;

      this.clientsFound = this.clientsFound.filter((x) => x !== cliente);
      this.messageData.clients.push(cliente);
    },

    removeClient(cliente) {
      this.messageData.clients = this.messageData.clients.filter((x) => x !== cliente);
    },

    async getMessage() {
      try {
        const message = await api.get('/messages/get/' + this.$router.history.current.params.id)
        if(message.data.error == 0){
          this.messageData = message.data.messages
          this.messageData.clients = JSON.parse(message.data.messages.clients)
          // this.messageData.day_week = JSON.parse(message.data.messages.day_week)
          this.semana = JSON.parse(this.messageData.day_week)
          
          if(this.messageData.clients.length > 0){
            this.exclusiva = true
          }
        }
      } catch (error) {
        this.$toast.error("Problema ao listar mensagem!")
      }
    },

    async updateMessage() {
      try {
         const payload = {
          day_week: this.semana,
          message: this.messageData.message,
          clients: this.exclusiva ? this.messageData.clients : [],
          hour: this.messageData.hour,
        };
        const message = await api.put('/messages/' + this.$router.history.current.params.id, payload)
        if(message.data.error == 0){
          this.$toast.success(message.data.message);
          this.getMessage()
        } else {
          this.$toast.error(message.data.message);
        }
      } catch (error) {
        this.$toast.error("Problema ao listar mensagem!")
      }
    }
  },
};
</script>
