<template>
<div>
    <loading :active="loadingIsCompleted"> </loading>
  <cliente-layout :active="2">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Treinos</h4>
        </div>
        <div class="card-body">
          <CadTreino v-if="trainingData" :treinos="trainingData" @edit-training="updateTraining" />
        </div>
      </div>
    </div>
  </cliente-layout>
  </div>
</template>

<script>
import ClienteLayout from "../../../components/Layouts/ClienteLayout.vue";
import CadTreino from "../../../components/Form/CadTreino.vue"
import api from "../../../services/funcoes-service";
// const user = JSON.parse(localStorage.getItem('user')).user
import Loading from "../../../components/Layouts/Loading.vue";
export default {
  components: {
    ClienteLayout,
    CadTreino,
    Loading
  },
  data() {
    return {
      trainingData: null,
      loadingIsCompleted: true,
    };
  },
  created(){
    this.getInfoTraining()
  },
  methods: {
    async getInfoTraining() {
      try {
        const training = await api.get(`/training/get/client/${this.$router.history.current.params.id}`)
        if(training.data.error == 0){
          this.trainingData = training.data.training;
          console.log(this.trainingData)
          this.$toast.success(training.data.message);
        }else{
          this.$toast.error(training.data.message);
        }
        this.loadingIsCompleted = false;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    },

    async updateTraining(exercicios){
      try {
       console.log(exercicios)
        const training = await api.put(`/training/client/${this.$router.history.current.params.id}`, {training: exercicios});
        if(training.data.error == 0){
          this.trainingData = training.data.training;
          this.$toast.success(training.data.message);
        }else{
          this.$toast.error(training.data.message);
        }
        this.loadingIsCompleted = false;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    }
  },
};
</script>
