<template>
<div>
  <loading :active="loadingIsCompleted"> </loading>
  <main-layout :active="3">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
          <div class="card text-center pt-2">
            <div class="card-body">
              <p class="mb-1">Total Clientes</p>
              <h4>{{clientsLogsData.length}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Clientes</h4>
              <input
                type="text"
                placeholder="Buscar por nome do Cliente"
                style="width: 40%"
                class="form-control"
                v-model="clientNameSearch"
              />
          <router-link class="btn btn-primary" to="/dashboard/clients/create-client">Cadastrar novo Cliente</router-link>
        </div>
        <div class="card-body">
          <div class="transaction-table">
            <div class="table-responsive">
              <table class="table table-striped mb-0 table-responsive-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NOME</th>
                    <th>WHATSAPP</th>
                    <th>STATUS</th>
                    <th>DATA PAGAMENTO</th>
                    <th>VALOR PAGAMENTO</th>
                    <th>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  <div v-if="clientsLogsData.length <= 0">
                    <h4> SEM REGISTROS </h4>
                    </div> 
                  <tr v-for="item in clientsLogsData" :key="item.id">
                    <td>#{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.whatsapp}}</td>
                    <td>{{item.status}}</td>
                    <td>{{formatDate(item.date_limit)}}</td>
                    <td>R${{item.price_default}}</td>
                    <td>
                      <router-link :to="'/dashboard/clients/view/' + item.id" class="btn btn-primary" style="margin-right: 3px"><i class="fa fa-eye" aria-hidden="true"></i></router-link> 
                      <router-link :to="'/dashboard/clients/view/' + item.id" class="btn btn-primary"><i class="fa fa-pencil" aria-hidden="true"></i></router-link> 
                    </td>
                  </tr>
                </tbody>
                <br>
              </table>
              <div style="margin-left: 80%;">
                <ul class="pagination">
                <li class="page-item">
                  <button class="page-link" @click="getClients(paginationData.current_page - 1)" aria-label="Anterior">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Anterior</span>
                  </button>
                </li>
                <li class="page-item"><button class="page-link" @click="getClients(1)">1</button></li>
                <li class="page-item"><button class="page-link" @click="getClients(2)">2</button></li>
                <li class="page-item"><button class="page-link" @click="getClients(3)">3</button></li>
                <li class="page-item"><button class="page-link" @click="getClients(4)">4</button></li>
                <li class="page-item"><button class="page-link" @click="getClients(5)">5</button></li>
                <li class="page-item">
                  <button class="page-link" @click="getClients(paginationData.current_page + 1)" aria-label="Próximo">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Próximo</span>
                  </button>
                </li>
                </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
  </div>
</template>

<script>
import MainLayout from "../../components/Layouts/MainLayout.vue";
import api from "../../services/funcoes-service";
// const user = JSON.parse(localStorage.getItem('user')).user
import moment from "moment"
import Loading from "../../components/Layouts/Loading.vue";
export default {
  components: { MainLayout, Loading },
  data() {
    return {
      clientsLogsData: null,
      clientNameSearch: "",
      paginationData: null,
      loadingIsCompleted: true,
    };
  },
  watch: {
    async clientNameSearch(cod){
      try {
        const clients = await api.get(
          "/clients/view/name/" + cod
      );
        this.clientsLogsData = clients.data.client;
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    }
  },
  async mounted() {
    this.getClients()
  },
  methods: {
    formatDate(date){
      const newdate = `${moment(date)
      .utc()
      .format("DD/MM/YYYY")}`;
      this.date_end = newdate;
      return newdate;
    },

    async getClients(page){
      try {
        if(!page) page = 1
        if(page == -1) page = 1
      const clients = await api.get(`/clients/get/all?page=${page}&limit=10`);
      if (clients.data.error == 0) {
        this.clientsLogsData = clients.data.clients.data;
        this.paginationData = clients.data.clients.meta;
        this.loadingIsCompleted = false;
      }
    } catch (error) {
      this.$router.go(0)
      this.$toast.error("Problema ao consultar seus dados!");
    }
    }
  }
};

</script>
