import Vue from 'vue'
import Vuex from 'vuex'
/*eslint-disable*/

// Modules
import { auth } from './auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
	},
	strict: process.env.DEV,

})
