/* eslint-disable class-methods-use-this */
import axios from 'axios'
import authHeader from './auth-header'
// const API_URL = process.env.API_URL || 'http://127.0.0.1:3333/api/v1'
const API_URL = 'https://vida-on.herokuapp.com/api/v1'

class AuthService {
	login(user) {
		return axios
			.post(`${API_URL}/user/login`, {
				email: user.email,
				password: user.password,
			})
			.then(response => {
				console.log(response)
				if (response.data.token) {
					localStorage.setItem('user', JSON.stringify(response.data))
				}

				return response.data
			})
	}

	valid() {
		return axios
			.get(`${API_URL}/user/me`, { headers: authHeader() } )
			.then(response => {
				console.log(response)
				if (response.data.token) {
					localStorage.setItem('user', JSON.stringify(response.data))
					return true;
				}
				return false
			}).catch(er => {
				console.log(er)
				return false
			})
	}

	logout() {
		localStorage.removeItem('user')
	}
}

export default new AuthService()
