<template>
  <Carousel
    :per-page="1"
    :loop="true"
    :navigation-enabled="true"
    :pagination-enabled="false"
    navigation-prev-label="<i class='la la-arrow-left'></i>"
    navigation-next-label="<i class='la la-arrow-right'></i>"
    class="owl-carousel owl-theme landing-carousel"
  >
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/1.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
    <!-- slide end -->
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/2.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
    <!-- slide end -->
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/1.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
    <!-- slide end -->
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/1.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
    <!-- slide end -->
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/2.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
    <!-- slide end -->
    <Slide class="slide d-md-flex align-items-center">
      <div class="col-12 col-md-6 p-0">
        <img
          class="img-fluid rounded-start pe-md-4"
          src="../../assets/images/testimonial/1.jpg"
          loading="lazy"
          alt="…"
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="p-4">
          <span class="mb-3"
            ><img
              class="img-fluid"
              src="#"
              loading="lazy"
              alt="…"
          /></span>
          <p class="text-gray">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi
            voluptas dignissimos similique quas molestiae doloribus recusandae
            voluptatem et repudiandae veritatis.
          </p>
          <div class="mt-3">
            <h3 class="h4 fw-semi-bold h5">Mr John Doe</h3>
            <p class="text-gray">CEO, Example Company</p>
          </div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "LandingSlider",
  components: { Carousel, Slide },
};
</script>
