<template>
  <div id="main-wrapper" class="show">
    <Header :dark="dark" />
    <Sidebar :active="active" />
    <Title :dark="dark" />
    <div class="content-body">
      <div class="container">
        <div class="row">
          <slot />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../Dashboard/Footer.vue";
import Header from "../Dashboard/Header.vue";
import Sidebar from "../Dashboard/Sidebar.vue";
import Title from "../Dashboard/Title.vue";
export default {
  components: { Header, Sidebar, Footer, Title },
  name: "MainLayout",
  props: {
    title: Boolean,
    active: Number,
  },
  data() {
    return {
      dark: window.location.pathname.includes("dark"),
    };
  },
  mounted() {
    const body = document.querySelector("body");
    if (this.dark) {
      body.className = "dark";
    } else {
      body.className = "light";
    }
  },
  methods: {
  },
};
</script>
