<template>
  <div class="show">
    <b-tabs content-class="mt-3">
      <div v-for="semana in semanas" :key="semana">
        <b-tab :title="semana" :active="semana == 'Segunda' ? true : false">
          <div class="form-group col-xl-12">
            <button class="btn btn-primary" style="margin-left: 90%" @click="addFood(semana)">
              + Refeição
            </button>
          </div>
          <div
            class="card col-xl-5"
            style="margin-right: 30px; display: inline-block"
            v-for="food in foods[semana]"
            :key="food"
          >
            <div class="card-header">
              <label>Refeição {{ food.id }}</label>
                      <input
                        style="border-color: #e2815e; width: 150px; display:inline-block"
                        maxlength="5"
                        type="text"
                        v-model="food.time"
                        placeholder="12:00"
                        class="form-control"
                      />
              <button
                class="btn"
                @click="removeFood(food.id, semana)"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-xl-12">
                  <div class="media align-items-center mb-3">
                    <div class="media-body">
                      <input
                        type="text"
                        class="form-control"
                        v-model="food.nome"
                        placeholder="Nome da Refeição (ex: Almoço)"
                      />
                      <br />
                      <textarea
                        id="story"
                        class="form-control"
                        name="story"
                        rows="5"
                        cols="33"
                        v-model="food.descricao"
                        placeholder="Descrição do cardápio"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </div>
    </b-tabs>
    <hr>
    <button class="btn btn-success" v-show="food" @click="updateData()">Atualizar</button>
  </div>
</template>

<script>
export default {
  name: "CadAlimentacao",
  props: {
    food: Object,
    title: Boolean,
    active: Number,
  },
  data() {
    return {
      foods: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: [],
        Sabado: [],
        Domingo: [],
      },
      semanas: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sabado",
        "Domingo",
      ],
    };
  },
  mounted() {
    this.checkOnlyView()
    this.$emit("save-food", this.foods);
  },
  methods: {
    addFood(semana) {
      let indiceInicial = 1;
      let ultimoId = null;
      if (this.foods[semana].length !== 0) {
        ultimoId =
          this.foods[semana][this.foods[semana].length - 1].id + 1;
      }

      const payload = {
        id: ultimoId || indiceInicial,
      };

      this.foods[semana].push(payload);
    },
    removeFood(id, semana) {
      const novoArray = this.foods[semana].filter((el) => el.id !== id);
      this.foods[semana] = novoArray;
    },
     checkOnlyView(){
      if(this.food){
        this.foods.Segunda = JSON.parse(this.food.monday)
        this.foods['Terça'] = JSON.parse(this.food.tuesday)
        this.foods.Quarta = JSON.parse(this.food.wednesday)
        this.foods.Quinta = JSON.parse(this.food.thursday)
        this.foods.Sexta = JSON.parse(this.food.friday)
        this.foods.Sabado = JSON.parse(this.food.saturday)
        this.foods.Domingo = JSON.parse(this.food.sunday)
      }
    },
    updateData(){
      if(!this.food) return
      this.foods.Segunda = this.foods.Segunda.filter(x=> x.nome)
      this.foods['Terça'] = this.foods['Terça'].filter(x=> x.nome)
      this.foods.Quarta = this.foods.Quarta.filter(x=> x.nome)
      this.foods.Quinta = this.foods.Quinta.filter(x=> x.nome)
      this.foods.Sexta = this.foods.Sexta.filter(x=> x.nome)
      this.foods.Sabado = this.foods.Sabado.filter(x=> x.nome)
      this.foods.Domingo = this.foods.Domingo.filter(x=> x.nome)
      this.$emit("edit-food", this.foods);
    }
  },
};
</script>
