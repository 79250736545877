<template>
  <div>
    <loading :active="loadingIsCompleted"> </loading>
    <cliente-layout :active="1">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Informações de Cadastro</h4>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-xl-6">
                <label class="me-sm-2">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Carla Pascle"
                  v-model="clientData.name"
                />
              </div>
              <div class="form-group col-xl-6">
                <label class="me-sm-2">Whatsapp</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="clientData.whatsapp"
                />
              </div>
              <div class="form-group col-xl-6">
                <label class="me-sm-2">Validade do Contrato</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="clientData.date_limit"
                />
              </div>
              <div class="form-group col-xl-6">
                <label class="me-sm-2">Valor Cobrado</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="clientData.price_default"
                />
              </div>
              <div class="form-group col-xl-12">
                <label class="toggle">
                  <label class="me-sm-2">Status</label>
                  <input
                    class="toggle-checkbox"
                    type="checkbox"
                    :checked="clientData.status == 1 ? true : false"
                  />
                  <span class="toggle-switch"></span>
                </label>
              </div>

              <div class="form-group col-xl-12">
                <label class="toggle">
                  <label class="me-sm-2">Permitir mensagem programada</label>
                  <input
                    class="toggle-checkbox"
                    type="checkbox"
                    :checked="
                      clientData.allow_message_whatsapp == 1 ? true : false
                    "
                  />
                  <span class="toggle-switch"></span>
                </label>
              </div>

              <div class="col-12">
                <button class="btn btn-success px-3" @click="update">Atualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </cliente-layout>
  </div>
</template>

<script>
import ClienteLayout from "../../../components/Layouts/ClienteLayout.vue";
import api from "../../../services/funcoes-service";
// const user = JSON.parse(localStorage.getItem('user')).user
import Loading from "../../../components/Layouts/Loading.vue";
import moment from "moment";
export default {
  components: {
    ClienteLayout,
    Loading,
  },
  data() {
    return {
      clientData: null,
      loadingIsCompleted: true,
    };
  },
  mounted() {
    this.getInfoClient();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    async getInfoClient() {
      try {
        const clients = await api.get(
          `/clients/view/${this.$router.history.current.params.id}`
        );
        if (clients.data.error == 0) {
          this.clientData = clients.data.client;
          this.clientData.date_limit = this.customFormatter(this.clientData.date_limit)
          this.clientData.price_default = 'R$' + this.clientData.price_default
          this.loadingIsCompleted = false;
        }
      } catch (error) {
        this.$router.go(0);
        this.$toast.error("Problema ao consultar seus dados!");
      }
    },

    async update(){
      try {
        delete this.clientData.created_at
        delete this.clientData.updated_at
        this.clientData.date_limit = moment(this.clientData.date_limit).format("YYYY-MM-DD");
        this.clientData.price_default = this.clientData.price_default.replace('R$', '')
        const clients = await api.put(
          `/clients/${this.$router.history.current.params.id}`, this.clientData
        );
        if (clients.data.error == 0) {
          this.getInfoClient()
          this.$toast.success(clients.data.message);
        }else{
          this.$toast.error(clients.data.message);
        }
      } catch (error) {
        this.$toast.error("Problema ao atualizar cadastro!");
      }
    }
  },
};
</script>
