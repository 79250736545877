<template>
  <div class="header dashboard">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <nav
            class="navbar navbar-expand-lg navbar-light px-0 justify-content-between"
          >
            <router-link class="navbar-brand" to="dashboard"
              ><img src="../../assets/images/logo.png" alt=""
            /></router-link>

            <div class="header-right d-flex my-2 align-items-center">
              <div class="language">
                <div class="dropdown">
                  <div
                    class="icon"
                    data-toggle="dropdown"
                    @click="activeDropDown('lang')"
                    :class="toggle === 'lang' && 'show'"
                  >
                    <i class="flag-icon flag-icon-br"></i>
                    <span>Português</span>
                  </div>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    :class="toggle === 'lang' && 'show'"
                  >
                    <router-link to="#" class="dropdown-item">
                      <i class="flag-icon flag-icon-us"></i> English
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="dashboard_log" >
                <div
                  class="profile_log dropdown"
                  @click="activeDropDown('profile')"
                  :class="toggle === 'profile' && 'show'"
                >
                  <div class="user" data-toggle="dropdown">
                    <span class="thumb"><i class="mdi mdi-account"></i></span>
                    <span class="arrow"><i class="la la-angle-down"></i></span>
                  </div>

                  <div
                    class="dropdown-menu dropdown-menu-right"
                    :class="toggle === 'profile' && 'show'"
                  >
                    <div class="user-email">
                      <div class="user">
                        <span class="thumb"
                          ><i class="mdi mdi-account"></i
                        ></span>
                        <div class="user-info">
                          <h6>{{user.name}}</h6>
                          <span>{{user.email}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="user-balance">
                      <div class="total">
                        <p>Vencimento</p>
                        <span>{{formatDate(user.date_expire)}}</span>
                      </div>
                    </div>
                    <router-link
                      to="/dashboard/settings"
                      class="dropdown-item"
                    >
                      <i class="mdi mdi-settings"></i> Configurações
                    </router-link>
                    <!-- <router-link :to="darkLink('lock')" class="dropdown-item">
                      <i class="mdi mdi-lock"></i> Bloquear
                    </router-link> -->
                    <button
                      class="dropdown-item logout"
                      @click="logout()"

                    >
                      <i class="mdi mdi-logout"></i> Sair
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let user = { name: '', email : '', date_expires: '',}
if(localStorage.getItem('user')){
  user = (JSON.parse(localStorage.getItem('user'))).user
}
import moment from 'moment'

export default {
  name: "Header",
  data() {
    return {
      toggle: false,
      user
    };
  },
  props: {
    dark: Boolean,
  },
  methods: {
    formatDate(date){
      const newdate = `${moment(date)
      .utc()
      .format("DD/MM/YYYY")}`;
      this.date_end = newdate;
      return newdate;
    },
    activeDropDown(value) {
      this.toggle = this.toggle === value ? "" : value;
    },
    darkLink(name) {
      console.log(this.dark);
      if (this.dark) {
        return `/${name}-dark`;
      } else {
        return `/${name}`;
      }
    },
    logout() {
			// Remove userData from localStorage
			localStorage.removeItem('user')
			localStorage.removeItem('show_toster_expired')
			localStorage.removeItem('show_toster_tradio')


			// Redirect to login page
			this.$router.push({ name: 'Signin' })
		},
  },
};
</script>
