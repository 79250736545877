import AuthService from '../services/auth-service'
const user2 = JSON.parse(localStorage.getItem('user'))
const initialState = user2
	? { status: { loggedIn: true }, user2 }
	: { status: { loggedIn: false }, user2: null }

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit }, user) {
			return AuthService.login(user).then(
				userReturn => {
					commit('loginSuccess', userReturn)
					return Promise.resolve(userReturn)
				},
				error => {
					commit('loginFailure')
					return Promise.reject(error)
				},
			)
		},
		logout({ commit }) {
			AuthService.logout()
			commit('logout')
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true
			state.user = user
		},
		loginFailure(state) {
			state.status.loggedIn = false
			state.user = null
		},
		logout(state) {
			state.status.loggedIn = false
			state.user = null
		},
	},
}
