<template>
    <div id="preloader" v-show="active">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    active: Boolean,
  },
};
</script>
