var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"currency_validate",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.formSubmit)}}},[_c('div',{staticClass:"mb-4 form-group"},[_c('label',{staticClass:"me-sm-2"},[_vm._v("Send")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("This field is required.")]),_c('div',{staticClass:"form-group d-flex"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"rules":"required","name":"currency","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency),expression:"currency"}],staticClass:"form-control",attrs:{"name":"currency"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currency=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Bitcoin")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Litecoin")])]),_c('label',{staticClass:"error",attrs:{"for":"currency"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"w-50",attrs:{"rules":"required","name":"usd_amount","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usd_amount),expression:"usd_amount"}],staticClass:"form-control",attrs:{"type":"text","name":"usd_amount","placeholder":"125.00 USD"},domProps:{"value":(_vm.usd_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.usd_amount=$event.target.value}}}),_c('label',{staticClass:"error",attrs:{"for":"currency"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"me-sm-2"},[_vm._v("Get")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("This field is required.")]),_c('div',{staticClass:"input-group mb-3"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"rules":"required","name":"currency_1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency_1),expression:"currency_1"}],staticClass:"form-control",attrs:{"name":"currency_1","aria-placeholder":"Bitconin"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currency_1=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"3","selected":""}},[_vm._v("Bitcoin")]),_c('option',{attrs:{"value":"4"}},[_vm._v("Litecoin")])]),_c('label',{staticClass:"error",attrs:{"for":"currency_1"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true),model:{value:(_vm.currency_1),callback:function ($$v) {_vm.currency_1=$$v},expression:"currency_1"}}),_c('ValidationProvider',{staticClass:"w-50",attrs:{"rules":"required","name":"usd_amount_1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usd_amount_1),expression:"usd_amount_1"}],staticClass:"form-control",attrs:{"type":"text","name":"usd_amount_1","placeholder":"125.00 USD"},domProps:{"value":(_vm.usd_amount_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.usd_amount_1=$event.target.value}}}),_c('label',{staticClass:"error",attrs:{"for":"usd_amount_1"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true),model:{value:(_vm.usd_amount_1),callback:function ($$v) {_vm.usd_amount_1=$$v},expression:"usd_amount_1"}})],1),_c('div',{staticClass:"d-flex justify-content-between mt-0 align-items-center"},[_c('p',{staticClass:" mb-0"},[_vm._v("Monthly Limit")]),_c('span',{staticClass:"mb-0 text-dark-400 fs-6 fw-semi-bold"},[_vm._v("$49750 remaining")])])]),_c('button',{staticClass:"btn btn-success btn-block",attrs:{"type":"submit","name":"submit"}},[_vm._v(" Exchange Now "),_c('i',{staticClass:"la la-arrow-right"})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }